<template>
  <el-main class="rh-table">
    <h1>Parcours clinique</h1>
    <app-table
      tablenom="parcourscliniques"
      :cols="colonnes"
      :items="parcourscliniques"
      :loadstatus="parcourscliniquesLoadStatus"
      :deletestatus="parcourscliniqueDeleteStatus"
      :formroutes="pcFormRoutes"
      :actions="pcActions"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      pcFormRoutes: {
        edit: { route: "parcourscliniqueedit", params: { idpc: "id" } },
        create: { route: "parcourscliniquecreate" },
      },
    };
  },
  computed: {
    parcourscliniquesLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.pc.parcourscliniquesLoadStatus;
    },
    parcourscliniqueDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.pc.parcourscliniqueDeleteStatus;
    },
    parcourscliniques() {
      return this.$store.state.pc.parcourscliniques;
    },
    colonnes() {
      return this.$store.state.pc.colonnes;
    },
    pcActions() {
      return this.$store.state.pc.actions;
    },
  },
};
</script>
